import React, { Component } from 'react'

import CyclePage from '../../containers/CyclePage'
import PrimaryLayout from '../../components/layouts/PrimaryLayout'
import pagesInfos from '../../config/i18n/pagesInfos'

const CycleHiver20222023 = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentCyclesEtStagesCycleHiver20222023}
    >
      <CyclePage cycle="hiver-2022-2023" />
    </PrimaryLayout>
  )
}

export default CycleHiver20222023